import React, { useEffect } from "react";
import WebDevelopment from "../assets/image/webDevelopment.png";
import ProjectsCompleted from "../assets/image/projects-completed.svg";
import HappyClients from "../assets/image/happy-clients.svg";
import ServeServices from "../assets/image/serve-services.svg";
import Available from "../assets/image/available.svg";

import Discuss from "../components copy/Discuss";
import Counter from "../components copy/Counter";

import '../assets/css/InnerPage.css';
import { useLocation } from "react-router-dom";
import Loader from "../components copy/Loader/Loader";
import Waves from "../components copy/Waves";

function Development() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <>
      <Loader />
      <Waves />
      <section className="inner-banner">
        <div className="container">
          <div className="banner-section">
            <div className="inner-banner-text">
              <h2 className="inner-heding">Build Scalable and Efficient Web Solutions</h2>
              <p className="inner-details">At <span>SATTVA</span>, we specialize in Back-End Development services that empower your digital presence with robust and scalable web solutions. Our experienced team leverages advanced technologies and best practices to ensure your web applications are secure, efficient, and capable of handling complex business operations.</p>
            </div>
            <div className="inner-banner-img">
              <figure>
                <img src={WebDevelopment} alt="Web Development section image" />
              </figure>
            </div>
          </div>
        </div>
      </section>
      <section className="our-values" id="WebDevelopment">
        <div className="container">
          <h2 className="out-line-heading">What We Offer</h2>
          <div className="value-card">
            <div className="value-cards cards-one">
              <p className="value-head">Custom Back-End Development</p>
              <p className="value-details">Designing and developing tailored solutions that align with your unique business requirements and goals.</p>
              <p className="value-details">Implementing scalable architecture to accommodate future growth and evolving business needs.</p>
            </div>
            <div className="value-cards cards-two">
              <p className="value-head">API Development and Integration</p>
              <p className="value-details">Creating robust APIs to facilitate seamless data exchange and integration between different software systems.</p>
              <p className="value-details">Ensuring API security and performance optimization for enhanced functionality.</p>
            </div>
            <div className="value-cards cards-three">
              <p className="value-head">Database Management</p>
              <p className="value-details">Utilizing leading database management systems (e.g., MySQL, PostgreSQL, MongoDB) to store and manage data efficiently.</p>
              <p className="value-details">Designing database schemas and optimizing queries for improved performance and reliability.</p>
            </div>
            <div className="value-cards cards-four">
              <p className="value-head">Server-Side Scripting</p>
              <p className="value-details">Implementing server-side logic using languages like PHP, Python, Node.js to support application functionality.</p>
              <p className="value-details">Ensuring smooth operation and high performance of web applications through effective server-side scripting.</p>
            </div>
          </div>
        </div>
      </section>
      <section className="why-choose" id="WebDevelopment">
        <div className="container">
          <h2 className="out-line-heading">Our Approach</h2>
          <div className="choose-card">
            <div className="choose-cards choose-one">
              <p className="choose-head">Consultation and Requirements Analysis</p>
              <p className="choose-details">Understanding your business goals, technical requirements, and user needs to define a comprehensive development strategy.</p>
            </div>
            <div className="choose-cards choose-two">
              <p className="choose-head">Architecture Design</p>
              <p className="choose-details">Designing scalable and secure architecture plans that lay the foundation for robust web solutions.</p>
            </div>
            <div className="choose-cards choose-three">
              <p className="choose-head">Development and Testing</p>
              <p className="choose-details">Agile development methodologies with rigorous testing to ensure bug-free functionality and seamless integration.</p>
            </div>
            <div className="choose-cards choose-four">
              <p className="choose-head">Deployment and Maintenance</p>
              <p className="choose-details">Smooth deployment processes followed by proactive maintenance and support to ensure ongoing reliability and performance.</p>
            </div>
          </div>
          <div className="counter-section">
            <Counter img={ProjectsCompleted} number="20" label="Projects Completed" />
            <Counter img={HappyClients} number="12" label="Happy Clients" />
            <Counter img={ServeServices} number="50" label="Serve Services" />
            <Counter img={Available} number="24" label="Support Available" />
          </div>
        </div>
      </section>
      <Discuss />
    </>
  )
}

export default Development;
