import React, { useEffect, useState } from "react";
import Logo from "../assets/image/sattva.svg";
import Call from '../assets/image/phone.svg';
import Mail from '../assets/image/mail.svg';
import Location from '../assets/image/location.svg';
import Loader from "../components copy/Loader/Loader";
import { Link, useLocation } from "react-router-dom";
import Waves from "../components copy/Waves";
import { Helmet } from "react-helmet-async";

const Contact = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        services: '',
        country: '',
        state: '',
        city: '',
        message: '',
    });

    const [errors, setErrors] = useState({});

    // Validation function
    const validate = () => {
        const newErrors = {};
        if (!formData.firstName) newErrors.firstName = 'First name is required';
        if (!formData.lastName) newErrors.lastName = 'Last name is required';
        if (!formData.email || !/\S+@\S+\.\S+/.test(formData.email)) newErrors.email = 'Valid email is required';
        if (!formData.phone || !/^\d+$/.test(formData.phone)) newErrors.phone = 'Valid phone number is required';
        if (!formData.services) newErrors.services = 'Selecting a service is required';
        if (!formData.country) newErrors.country = 'Selecting a country is required';
        if (!formData.state) newErrors.state = 'Selecting a state is required';
        if (!formData.city) newErrors.city = 'City is required';
        if (!formData.message) newErrors.message = 'Message is required';

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
        validate();
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (validate()) {
            console.log(formData);
            setFormData({
                firstName: '',
                lastName: '',
                email: '',
                phone: '',
                services: '',
                country: '',
                state: '',
                city: '',
                message: '',
            });
        }
    };
    return (
        <>
            <Helmet>
                <title>Contact Pravin Lagariya | Sattva - Get in Touch for Professional Services</title>
                <meta name="description" content="Contact Pravin Lagariya through Sattva for professional web design, digital marketing, and branding services. Let's collaborate on your next project." />
                <meta name="keywords" content="Contact Pravin Lagariya, Sattva, get in touch, professional services, web design, branding" />
                <link rel="canonical" href="https://pravinlagariya.in/contact" />
            </Helmet>
            <Loader />
            <Waves />
            <section className="inner-banner">
                <div className="container">
                    <div className="banner-section">
                        <div className="inner-banner-text">
                            <h2 className="inner-heding">Get in Touch with Us</h2>
                            <p className="inner-details">At <span>SATTVA</span>, we're here to help you grow your business digitally. Whether you have a question, need a consultation, or want to start a project, we’d love to hear from you. Reach out to us through any of the methods below, and we’ll get back to you as soon as possible.</p>
                        </div>
                        <div className="inner-banner-img">
                            <figure>
                                <img src={Logo} alt="Logo" />
                            </figure>
                        </div>
                    </div>
                </div>
            </section>
            <section className="contact">
                <div className="container">
                    <h2 className="out-line-heading">Send Us a Message</h2>
                    <p className="inner-details">Have a specific question or request? Fill out the form below, and our team will get back to you shortly.</p>
                    <form onSubmit={handleSubmit} className="contact-form">
                        <div className="sm:col-span-3">
                            <label htmlFor="first-name">First name</label>
                            <div className="mt-2">
                                <input
                                    type="text"
                                    name="firstName"
                                    id="first-name"
                                    value={formData.firstName}
                                    onChange={handleChange}
                                    autoComplete="given-name"
                                />
                                {errors.firstName && <span className="error-message">{errors.firstName}</span>}
                            </div>
                        </div>

                        <div className="sm:col-span-3">
                            <label htmlFor="last-name">Last name</label>
                            <div className="mt-2">
                                <input
                                    type="text"
                                    name="lastName"
                                    id="last-name"
                                    value={formData.lastName}
                                    onChange={handleChange}
                                    autoComplete="family-name"
                                />
                                {errors.lastName && <span className="error-message">{errors.lastName}</span>}
                            </div>
                        </div>

                        <div className="md:col-span-2 sm:col-span-3">
                            <label htmlFor="email">Email address</label>
                            <div className="mt-2">
                                <input
                                    id="email"
                                    name="email"
                                    type="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    autoComplete="email"
                                />
                                {errors.email && <span className="error-message">{errors.email}</span>}
                            </div>
                        </div>

                        <div className="md:col-span-2 sm:col-span-3">
                            <label htmlFor="phone">Phone</label>
                            <div className="mt-2">
                                <input
                                    id="phone"
                                    name="phone"
                                    type="text"
                                    value={formData.phone}
                                    onChange={handleChange}
                                />
                                {errors.phone && <span className="error-message">{errors.phone}</span>}
                            </div>
                        </div>

                        <div className="md:col-span-2 sm:col-span-3">
                            <label htmlFor="services">Services</label>
                            <div className="mt-2">
                                <select
                                    id="services"
                                    name="services"
                                    value={formData.services}
                                    onChange={handleChange}
                                >
                                    <option value="">Select Services</option>
                                    <option value="graphic-uiux">Graphic & UI/UX Design</option>
                                    <option value="web-design-dev">Web Design And Development</option>
                                    <option value="digital-marketing">Digital Marketing</option>
                                </select>
                                {errors.services && <span className="error-message">{errors.services}</span>}
                            </div>
                        </div>

                        <div className="md:col-span-2 sm:col-span-3">
                            <label htmlFor="country">Country</label>
                            <div className="mt-2">
                                <select
                                    id="country"
                                    name="country"
                                    value={formData.country}
                                    onChange={handleChange}
                                >
                                    <option value="">Select Country</option>
                                    <option value="us">United States</option>
                                    <option value="ca">Canada</option>
                                    <option value="mx">Mexico</option>
                                </select>
                                {errors.country && <span className="error-message">{errors.country}</span>}
                            </div>
                        </div>

                        <div className="md:col-span-2 sm:col-span-3">
                            <label htmlFor="state">State</label>
                            <div className="mt-2">
                                <select
                                    id="state"
                                    name="state"
                                    value={formData.state}
                                    onChange={handleChange}
                                >
                                    <option value="">Select State</option>
                                    <option value="us">United States</option>
                                    <option value="ca">Canada</option>
                                    <option value="mx">Mexico</option>
                                </select>
                                {errors.state && <span className="error-message">{errors.state}</span>}
                            </div>
                        </div>

                        <div className="md:col-span-2 sm:col-span-3">
                            <label htmlFor="city">City</label>
                            <div className="mt-2">
                                <input
                                    type="text"
                                    name="city"
                                    id="city"
                                    value={formData.city}
                                    onChange={handleChange}
                                    autoComplete="address-level2"
                                />
                                {errors.city && <span className="error-message">{errors.city}</span>}
                            </div>
                        </div>

                        <div className="sm:col-span-6">
                            <label htmlFor="message">Message</label>
                            <div className="mt-2">
                                <textarea
                                    name="message"
                                    id="message"
                                    value={formData.message}
                                    onChange={handleChange}
                                    autoComplete="off"
                                />
                                {errors.message && <span className="error-message">{errors.message}</span>}
                            </div>
                        </div>

                        <div className="sm:col-span-6">
                            <button type="submit" id="submit">Submit</button>
                        </div>
                    </form>
                </div>
            </section >
            <section className="contact">
                <div className="container">
                    <h2 className="out-line-heading">Contact Now</h2>
                    <div className="contact-card">
                        <div className='contact-logo'>
                            <ul>
                                <li>
                                    <Link to='/'>
                                        <img src={Call} alt='Call' />
                                        +91 123456789
                                    </Link>
                                </li>
                                <li>
                                    <Link to='/'>
                                        <img src={Mail} alt='Mail' />
                                        mail@gmail.com
                                    </Link>
                                </li>
                                <li>
                                    <Link to='/'>
                                        <img src={Location} alt='Location' />
                                        Adress
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div className='contact-map'>
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3671.601404185821!2d72.52632727436806!3d23.038403065739026!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e84b6e49bc3e3%3A0x2059060635cbdaca!2sVastrapur%20Lake!5e0!3m2!1sen!2sin!4v1720095714707!5m2!1sen!2sin" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Contact;