import React, { useEffect } from "react";
import DigitalMarketing from "../assets/image/digitalMarketing.png";
import ProjectsCompleted from "../assets/image/projects-completed.svg";
import HappyClients from "../assets/image/happy-clients.svg";
import ServeServices from "../assets/image/serve-services.svg";
import Available from "../assets/image/available.svg";

import Discuss from "../components copy/Discuss";
import Counter from "../components copy/Counter";

import '../assets/css/InnerPage.css';
import { useLocation } from "react-router-dom";
import Loader from "../components copy/Loader/Loader";
import Waves from "../components copy/Waves";

function Marketing() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <>
      <Loader />
      <Waves />
      <section className="inner-banner">
        <div className="container">
          <div className="banner-section">
            <div className="inner-banner-text">
              <h2 className="inner-heding">Amplify Your Online Presence with Strategic Digital Marketing</h2>
              <p className="inner-details">At <span>SATTVA</span>, we offer comprehensive Digital Marketing services designed to elevate your brand and drive measurable results. Our expert team utilizes cutting-edge strategies and tools to create and execute marketing campaigns that resonate with your target audience and achieve your business goals.</p>
            </div>
            <div className="inner-banner-img">
              <figure>
                <img src={DigitalMarketing} alt="Digital Marketing section image" />
              </figure>
            </div>
          </div>
        </div>
      </section>
      <section className="our-values" id="Marketing">
        <div className="container">
          <h2 className="out-line-heading">What We Offer</h2>
          <div className="value-card">
            <div className="value-cards cards-one">
              <p className="value-head">Search Engine Optimization (SEO)</p>
              <p className="value-details">Enhancing your website’s visibility on search engines through on-page and off-page optimization techniques.</p>
              <p className="value-details">Conducting keyword research and implementing SEO best practices to drive organic traffic and improve search rankings.</p>
            </div>
            <div className="value-cards cards-two">
              <p className="value-head">Pay-Per-Click (PPC) Advertising</p>
              <p className="value-details">Develop cohesive visual identities, including color palettes, typography, and brand guidelines.</p>
            </div>
            <div className="value-cards cards-three">
              <p className="value-head">Marketing Collaterals</p>
              <p className="value-details">Creating and managing targeted ad campaigns on platforms like Google Ads and Bing Ads to attract high-quality leads.</p>
              <p className="value-details">Optimizing ad spend and ROI through continuous monitoring and adjustments.</p>
            </div>
            <div className="value-cards cards-four">
              <p className="value-head">Social Media Marketing</p>
              <p className="value-details">Developing and executing social media strategies to engage with your audience on platforms like Facebook, Instagram, Twitter, and LinkedIn.</p>
              <p className="value-details">Creating compelling content, managing social media accounts, and running targeted ad campaigns to build brand awareness and loyalty.</p>
            </div>
            <div className="value-cards cards-one">
              <p className="value-head">Content Marketing</p>
              <p className="value-details">Crafting valuable and relevant content that attracts, engages, and converts your target audience.</p>
              <p className="value-details">Implementing content strategies that include blog posts, articles, infographics, videos, and more to boost online presence and authority.</p>
            </div>
            <div className="value-cards cards-two">
              <p className="value-head">Email Marketing</p>
              <p className="value-details">Designing and executing effective email marketing campaigns to nurture leads and maintain customer relationships.</p>
              <p className="value-details">Utilizing personalized and segmented email strategies to maximize open rates and conversions.</p>
            </div>
          </div>
        </div>
      </section>
      <section className="why-choose" id="Marketing">
        <div className="container">
          <h2 className="out-line-heading">Our Approach</h2>
          <div className="choose-card">
            <div className="choose-cards choose-one">
              <p className="choose-head">Discovery and Strategy</p>
              <p className="choose-details">Dive deep into your brand’s values, target audience, and business goals to tailor our design approach.</p>
            </div>
            <div className="choose-cards choose-two">
              <p className="choose-head">Design Iteration</p>
              <p className="choose-details">Understanding your business objectives, target audience, and competitive landscape to develop a tailored digital marketing strategy.</p>
            </div>
            <div className="choose-cards choose-three">
              <p className="choose-head">Implementation</p>
              <p className="choose-details">Executing data-driven campaigns using industry best practices and innovative techniques to achieve your marketing goals.</p>
            </div>
            <div className="choose-cards choose-four">
              <p className="choose-head">Monitoring and Optimization</p>
              <p className="choose-details">Continuously analyzing campaign performance and making data-informed adjustments to optimize results and ROI.</p>
            </div>
            <div className="choose-cards choose-five">
              <p className="choose-head">Reporting and Analysis</p>
              <p className="choose-details">Providing detailed reports and insights to track progress, measure success, and identify opportunities for further improvement.</p>
            </div>
          </div>
          <div className="counter-section">
            <Counter img={ProjectsCompleted} number="20" label="Projects Completed" />
            <Counter img={HappyClients} number="12" label="Happy Clients" />
            <Counter img={ServeServices} number="50" label="Serve Services" />
            <Counter img={Available} number="24" label="Support Available" />
          </div>
        </div>
      </section>
      <Discuss />
    </>
  )
}

export default Marketing;
