import React from 'react';
import '../Button/Button.css';
import { Link } from 'react-router-dom';

export function Button({ link, name, space }) {
  return (
    <div className={space}>
      <Link className="btn" to={link}>{name}</Link>
    </div>
  )
}

export function ButtonNew({ link, name, space }) {
  return (
    <div className={space}>
      <Link className="btn btn-new" to={link}>{name}</Link>
    </div>
  )
}

// export default Button
