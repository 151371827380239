import React, { useEffect, useRef, useState } from "react";
import '../assets/css/Counter.css';

const Counter = ({ number, label, img }) => {
    const [count, setCount] = useState(0);
    const [animated, setAnimated] = useState(false);
    const ref = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
            if (!animated && ref.current && isElementInViewport(ref.current)) {
                setAnimated(true);
                animateNumber();
            }
        };

        const animateNumber = () => {
            const start = 0, end = parseInt(number), duration = 2000;
            const range = end - start, increment = end > start ? 1 : -1;
            const stepTime = Math.abs(Math.floor(duration / range));

            let current = start;
            const timer = setInterval(() => {
                current += increment;
                setCount(current);
                if ((increment === 1 && current >= end) || (increment === -1 && current <= end)) {
                    clearInterval(timer);
                }
            }, stepTime);

            return () => clearInterval(timer);
        };

        const isElementInViewport = (el) => {
            const rect = el.getBoundingClientRect();
            return rect.top >= 0 && rect.left >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && rect.right <= (window.innerWidth || document.documentElement.clientWidth);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [animated, number]);

    return (
        <div className="counter" ref={ref}>
            <div className="counter-img">
                <img src={img} alt="Counter Icon" />
            </div>
            <div className="counter-text">
                <p className="number">{count}+</p>
                <p>{label}</p>
            </div>
        </div>
    );
};

export default Counter;