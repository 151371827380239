import React, { useEffect } from "react";
import Webdesign from "../assets/image/webDesign.png";
import ProjectsCompleted from "../assets/image/projects-completed.svg";
import HappyClients from "../assets/image/happy-clients.svg";
import ServeServices from "../assets/image/serve-services.svg";
import Available from "../assets/image/available.svg";

import Discuss from "../components copy/Discuss";
import Counter from "../components copy/Counter";

import '../assets/css/InnerPage.css';
import { useLocation } from "react-router-dom";
import Loader from "../components copy/Loader/Loader";
import Waves from "../components copy/Waves";

function WebDesign() {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
    return (
        <>
            <Loader />
            <Waves />
            <section className="inner-banner">
                <div className="container">
                    <div className="banner-section">
                        <div className="inner-banner-text">
                            <h2 className="inner-heding">Craft Engaging Websites with Seamless User Experiences</h2>
                            <p className="inner-details">At <span>SATTVA</span>, we specialize in Web Design and Front-End Development services that combine creative design with cutting-edge technology. Our team collaborates closely to create visually appealing websites with intuitive interfaces and exceptional user experiences.</p>
                        </div>
                        <div className="inner-banner-img">
                            <figure>
                                <img src={Webdesign} alt="Web Design section image" />
                            </figure>
                        </div>
                    </div>
                </div>
            </section>
            <section className="our-values" id="WebDesign">
                <div className="container">
                    <h2 className="out-line-heading">Web Design Services</h2>
                    <div className="value-card">
                        <div className="value-cards cards-one">
                            <p className="value-head">Custom Website Design</p>
                            <p className="value-details">Tailored to reflect your brand identity and business goals, ensuring a unique online presence.</p>
                        </div>
                        <div className="value-cards cards-two">
                            <p className="value-head">Responsive Design</p>
                            <p className="value-details">Ensuring your website looks and functions flawlessly across all devices, enhancing user engagement.</p>
                        </div>
                        <div className="value-cards cards-three">
                            <p className="value-head">E-commerce Solutions</p>
                            <p className="value-details">Developing robust online stores with secure payment gateways and streamlined shopping experiences.</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="our-values" id="WebDesign">
                <div className="container">
                    <h2 className="out-line-heading">Front-End Development Services</h2>
                    <div className="value-card">
                        <div className="value-cards cards-one">
                            <p className="value-head">HTML5, CSS3, JavaScript</p>
                            <p className="value-details">Implementing modern web technologies to build interactive and responsive user interfaces.</p>
                        </div>
                        <div className="value-cards cards-two">
                            <p className="value-head">Frameworks and Libraries</p>
                            <p className="value-details">Utilizing frameworks like React.js, Angular, or Vue.js for scalable and efficient front-end development.</p>
                        </div>
                        <div className="value-cards cards-three">
                            <p className="value-head">Cross-Browser Compatibility</p>
                            <p className="value-details">Ensuring consistent performance and functionality across different web browsers.</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="why-choose" id="WebDesign">
                <div className="container">
                    <h2 className="out-line-heading">Our Approach</h2>
                    <div className="choose-card">
                        <div className="choose-cards choose-one">
                            <p className="choose-head">Strategy and Planning</p>
                            <p className="choose-details">Understanding your business objectives, target audience, and market landscape to define a tailored strategy.</p>
                        </div>
                        <div className="choose-cards choose-two">
                            <p className="choose-head">Design and Development</p>
                            <p className="choose-details">Collaborating closely with you to translate concepts into visually appealing designs and functional websites.</p>
                        </div>
                        <div className="choose-cards choose-three">
                            <p className="choose-head">User-Centered Design</p>
                            <p className="choose-details">Prioritizing user experience and usability to create intuitive interfaces that resonate with your audience.</p>
                        </div>
                        <div className="choose-cards choose-four">
                            <p className="choose-head">Performance Optimization</p>
                            <p className="choose-details">Optimizing front-end performance for fast loading times and smooth user interactions.</p>
                        </div>
                    </div>
                    <div className="counter-section">
                        <Counter img={ProjectsCompleted} number="20" label="Projects Completed" />
                        <Counter img={HappyClients} number="12" label="Happy Clients" />
                        <Counter img={ServeServices} number="50" label="Serve Services" />
                        <Counter img={Available} number="24" label="Support Available" />
                    </div>
                </div>
            </section>
            <Discuss />
        </>
    )
}

export default WebDesign;
