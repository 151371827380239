import React from "react";
import { Button } from "./Button/Button";

import '../assets/css/Discuss.css';

const Discuss = () => {
    return (
        <section className="discuss-section">
            <div className="container">
                <h2 className="banner-title"><span>G</span>R<span>O</span>W your business digitally?</h2>
                <p>Contact us today to take it to the next level.</p>
                <Button space='md:mt-10 mt-5' name="Start Growing" link='/contact' />
            </div>
        </section>
    )
}

export default Discuss;