import React, { useEffect, useState } from "react";
import { ButtonNew } from "../components copy/Button/Button";
import Logo from "../assets/image/sattva.png";
import ProjectsCompleted from "../assets/image/projects-completed.svg";
import HappyClients from "../assets/image/happy-clients.svg";
import ServeServices from "../assets/image/serve-services.svg";
import Available from "../assets/image/available.svg";
import AboutBanner from "../assets/image/aboutUs.png";
import DesignGU from "../assets/image/designGU.png";
import WebDesign from "../assets/image/webDesign.png";
import WebDevelopment from "../assets/image/webDevelopment.png";
import DigitalMarketing from "../assets/image/digitalMarketing.png";

import '../assets/css/Home.css';
import Counter from "../components copy/Counter";
import Discuss from "../components copy/Discuss";
import { useLocation } from "react-router-dom";
import Loader from "../components copy/Loader/Loader";
import Waves from "../components copy/Waves";
import { Helmet } from "react-helmet-async";

const Home = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    const initialCounters = [
        { id: 1, initialCount: 50 },
        { id: 2, initialCount: 100 },
        { id: 3, initialCount: 10000 },
        { id: 4, initialCount: 2 }
    ];

    const [counters, setCounters] = useState(initialCounters);

    useEffect(() => {
        const interval = setInterval(() => {
            setCounters(prevCounters => (
                prevCounters.map(counter => ({
                    ...counter,
                    initialCount: counter.initialCount < 30 ? counter.initialCount + 1 : counter.initialCount
                }))
            ));
        }, 1000); // Interval set to increment every second

        return () => clearInterval(interval);
    }, []);
    return (
        <>
            <Helmet>
                <title>Pravin Lagariya | Sattva - Professional Portfolio and Services</title>
                <meta name="description" content="Welcome to Pravin Lagariya's official website, Sattva. Explore my professional portfolio and discover a range of services designed to elevate your digital presence." />
                <meta name="keywords" content="Pravin Lagariya, Sattva, portfolio, web design, digital marketing, branding, services" />
                <link rel="canonical" href="https://pravinlagariya.in/" />
            </Helmet>
            <Loader />
            <Waves />
            <div>
                <section id="banner">
                    <div className="container">
                        <div className="banner">
                            <div className="banner-text">
                                <h1 className="banner-title"><span>G</span>R<span>O</span>W your Business digitally</h1>
                                <p className="semi-title">Explore our comprehensive services in graphic & UI/UX design, web development, and digital marketing. Together, we'll turn your vision into a thriving digital reality</p>
                                <ButtonNew space="mt-8" name="Contact" link="/contact" />
                            </div>
                            <div className="banner-img">
                                <figure>
                                    <img className="new-img" src={Logo} alt="Logo" />
                                </figure>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <section>
                    <div className="container">
                        <p className="semi-title brand-trust-title">Trusted by agencies around the globe.</p>
                        <div className="brand-trust">
                            <ul className="brand-group">
                                <li className="brand-logos">
                                    <figure>
                                        <img src={Logo} alt="" />
                                    </figure>
                                </li>
                                <li className="brand-logos">
                                    <figure>
                                        <img src={Logo} alt="" />
                                    </figure>
                                </li>
                                <li className="brand-logos">
                                    <figure>
                                        <img src={Logo} alt="" />
                                    </figure>
                                </li>
                                <li className="brand-logos">
                                    <figure>
                                        <img src={Logo} alt="" />
                                    </figure>
                                </li>
                                <li className="brand-logos">
                                    <figure>
                                        <img src={Logo} alt="" />
                                    </figure>
                                </li>
                                <li className="brand-logos">
                                    <figure>
                                        <img src={Logo} alt="" />
                                    </figure>
                                </li>
                            </ul>
                        </div>
                    </div>
                </section> */}
                <section>
                    <div className="container">
                        <div className="about-group">
                            <div>
                                <h2 className="out-line-heading">about us</h2>
                                {/* <h3 className="banner-title">About</h3> */}
                                <p className="semi-title">Driving Digital Success Through Creative Excellence</p>
                            </div>
                        </div>
                        <div className="about-section">
                            <div className="about-details">
                                <p>At <span>SATTVA</span>, we are driven by a passion for digital innovation and a commitment to our clients' success. Specializing in graphic & UI/UX design, web development, and digital marketing, we pride ourselves on delivering tailored solutions that elevate brands and exceed expectations.</p>
                                <p>Our graphic design services are tailored to visually communicate your brand's essence. Whether it's designing a new logo, creating branded marketing materials, or developing a cohesive visual identity, we blend creativity with strategic insights to ensure your brand stands out. Our UI/UX design expertise focuses on creating intuitive and engaging user interfaces that enhance usability and deliver exceptional user experiences. From wireframing and prototyping to final design implementation, we prioritize user-centric design principles to optimize interaction and engagement.</p>
                                <ButtonNew space="mt-8" name="About Us" link="/about" />
                            </div>
                            <div className="about-image">
                                <figure>
                                    <img className="new-img" src={AboutBanner} alt="About section image" />
                                </figure>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="container">
                        <div className="services-group">
                            <div className="services-heading">
                                <h2 className="out-line-heading">services</h2>
                                <p className="semi-title">Empowering Growth Through Creative Excellence</p>
                            </div>
                            <div className="services-card services-one">
                                <div className="service-group-one">
                                    <div className="services-text">
                                        <h2>Graphic & UI/UX Design</h2>
                                        <p>Transform your brand with our expert graphic design and UI/UX services. We create visually stunning designs that captivate audiences and enhance user experience. From logo design to complete brand identity development, our creative team ensures your visuals align with your brand's ethos and resonate with your target market.</p>
                                        <ButtonNew space='mt-4' name='Learn More' link='/graphic&ui-ux-design' />
                                    </div>
                                    <div className="services-image">
                                        <figure>
                                            <img className="new-img" src={DesignGU} alt="Graphic & UI/UX section image" />
                                        </figure>
                                    </div>
                                </div>
                            </div>
                            <div className="services-card services-two">
                                <div className="services-image">
                                    <figure>
                                        <img className="new-img" src={WebDesign} alt="Web Design section image" />
                                    </figure>
                                </div>
                                <div className="services-text">
                                    <h2>Web Design</h2>
                                    <p>Our web design and development services focus on creating websites that not only look impressive but also function seamlessly across all devices. Whether you're launching a new site or revamping an existing one, we prioritize user experience and responsive design to ensure optimal performance and user engagement.</p>
                                    <ButtonNew space='mt-4' name='Learn More' link='/web-design' />
                                </div>
                            </div>
                            <div className="services-card services-three">
                                <div className="services-image">
                                    <figure>
                                        <img className="new-img" src={WebDevelopment} alt="Web Development section image" />
                                    </figure>
                                </div>
                                <div className="services-text">
                                    <h2>Web Development</h2>
                                    <p>From custom web applications to scalable backend solutions, our web development expertise covers a wide range of functionalities and technologies. We leverage our technical proficiency to deliver robust, secure, and scalable solutions that meet your specific business requirements and support your growth objectives.</p>
                                    <ButtonNew space='mt-4' name='Learn More' link="/web-development" />
                                </div>
                            </div>
                            <div className="services-card services-one">
                                <div className="service-group-one">
                                    <div className="services-image">
                                        <figure>
                                            <img className="new-img" src={DigitalMarketing} alt="Digital Markrting section image" />
                                        </figure>
                                    </div>
                                    <div className="services-text">
                                        <h2>Digital Marketing</h2>
                                        <p>Drive traffic, generate leads, and increase conversions with our strategic digital marketing services. We develop customized digital marketing strategies tailored to your business goals. Our services include SEO, PPC advertising, social media marketing, email marketing, and content creation. Through data-driven insights and continuous optimization, we ensure your digital campaigns deliver maximum impact and measurable results.</p>
                                        <ButtonNew space='mt-4' name='Learn More' link='/digital-marketing' />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="counter-section">
                            <Counter img={ProjectsCompleted} number="20" label="Projects Completed" />
                            <Counter img={HappyClients} number="12" label="Happy Clients" />
                            <Counter img={ServeServices} number="50" label="Serve Services" />
                            <Counter img={Available} number="24" label="Support Available" />
                        </div>
                    </div>
                </section>
                <Discuss />
            </div>
        </>
    )
}

export default Home;