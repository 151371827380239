import React, { useEffect, useState } from "react";
import '../assets/css/Home.css';

const Waves = () => {
    const [opacity, setOpacity] = useState(1);
    const handleScroll = () => {
        const scrollTop = window.scrollY;
        const maxScroll = 250; // Adjust this value to control the scroll length required for full opacity change
        const newOpacity = 1 - scrollTop / maxScroll;

        setOpacity(newOpacity < 0 ? 0 : newOpacity);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (
        <>
            {/* <svg className="waves" style={{ opacity: opacity, transition: 'opacity 0.5s' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
                <defs>
                    <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s58 18 88 18 58-18 88-18 58 18 88 18v44h-352z" />
                </defs>
                <g className="parallax">
                    <use href="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.3)" />
                    <use href="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.2)" />
                    <use href="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.25)" />
                    <use href="#gentle-wave" x="48" y="7" fill="rgba(255,255,255,0.1)" />
                </g>
            </svg> */}
        </>
    )
}

export default Waves;