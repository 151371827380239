import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components copy/Header/Header';
import Footer from './components copy/Footer/Footer';
import Error from './pages/Error';
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import Design from './pages/Design';
import Services from './pages/Services';
import WebDesign from './pages/WebDesign';
import Development from './pages/WebDevelopment';
import Marketing from './pages/Marketing';
import ScrollTop from './components copy/ScrollTop/ScrollTop';
import './App.css';
import { HelmetProvider } from 'react-helmet-async';

const App = () => {
  return (
    <HelmetProvider>
      <Router>
        <Header />
        <div className='relative'>
          <ul className="circles">
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
          </ul>
          <ScrollTop />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/*" element={<Error />} />
            <Route path="/about" element={<About />} />
            <Route path="/services" element={<Services />} />
            <Route path="/contact" element={<Contact />} />

            <Route path="/graphic&ui-ux-design" element={<Design />} />{/* Graphic & UI/UX Design */}
            <Route path="/web-design" element={<WebDesign />} /> {/*Web Design*/}
            <Route path="/web-development" element={<Development />} /> {/*Web Design*/}
            <Route path="/digital-marketing" element={<Marketing />} /> {/*Digital Marketing*/}
          </Routes>
        </div>
        <Footer />
      </Router>
    </HelmetProvider>
  );
};

export default App;
