import React from "react";
import Logo from '../assets/image/sattva.svg';
import { ButtonNew } from "../components copy/Button/Button";

const Error = () => {
    return (
        <>
            <div className="container min-h-[calc(100vh_-_150px)] py-20">
                <div className="flex justify-center items-center h-full">
                    <div className="text-center text-white">
                        <img className="w-[200px] mx-auto" src={Logo} alt="logo" />
                        <h1 className="text-5xl mb-4"> <span className="text-bgOrange font-black">Oops!</span> Something Went Wrong</h1>
                        <p className="text-lg mb-10">It looks like the page you're looking for doesn't exist or has been moved. <br /> But don't worry, we're here to help you find your way.</p>
                        <ButtonNew name='Click to Home page' link='/' />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Error;