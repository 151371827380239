import React, { useEffect } from "react";
import ProjectsCompleted from "../assets/image/projects-completed.svg";
import HappyClients from "../assets/image/happy-clients.svg";
import ServeServices from "../assets/image/serve-services.svg";
import Available from "../assets/image/available.svg";
import Counter from "../components copy/Counter";
import AboutBanner from "../assets/image/aboutUs.png";

import '../assets/css/InnerPage.css';
import Discuss from "../components copy/Discuss";
import { useLocation } from "react-router-dom";
import Loader from "../components copy/Loader/Loader";
import Waves from "../components copy/Waves";
import { Helmet } from "react-helmet-async";

const About = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
    return (
        <>
            <Helmet>
                <title>About Pravin Lagariya | Sattva - Creative Professional & Digital Solutions</title>
                <meta name="description" content="Learn about Pravin Lagariya's journey in digital services and creative solutions. Sattva offers insights into my professional experiences and projects." />
                <meta name="keywords" content="Pravin Lagariya, Sattva, about, professional journey, creative solutions, digital services" />
                <link rel="canonical" href="https://pravinlagariya.in/about" />
            </Helmet>
            <Loader />
            <Waves />
            <section className="inner-banner">
                <div className="container">
                    <div className="banner-section">
                        <div className="inner-banner-text">
                            <h2 className="inner-heding">Welcome to SATTVA</h2>
                            <p className="inner-details">At <span>SATTVA</span>, we are passionate about helping businesses thrive in the digital landscape. Our mission is to empower your brand with innovative and effective digital solutions tailored to your unique needs. With expertise spanning graphic & UI/UX design, web design and development, and digital marketing, we are your one-stop destination for comprehensive digital growth.</p>
                        </div>
                        <div className="inner-banner-img">
                            <figure>
                                <img src={AboutBanner} alt="About section image" />
                            </figure>
                        </div>
                    </div>
                </div>
            </section>
            <section className="our-story">
                <div className="container">
                    <h2 className="out-line-heading">Our Story</h2>
                    <p className="inner-details">Founded in 2024, <span>SATTVA</span> was born out of a desire to help businesses navigate the complexities of the digital world. We saw a need for a holistic approach that combines creativity, technical prowess, and strategic thinking. Over the years, we have grown into a team of dedicated professionals who share a common goal: to see our clients succeed.</p>
                </div>
            </section>
            <section className="our-values" id="about">
                <div className="container">
                    <h2 className="out-line-heading">Our Values</h2>
                    <div className="value-card">
                        <div className="value-cards cards-one">
                            <p className="value-head">Innovation</p>
                            <p className="value-details">We stay ahead of industry trends to bring you the latest and most effective digital solutions.</p>
                        </div>
                        <div className="value-cards cards-two">
                            <p className="value-head">Quality</p>
                            <p className="value-details">Our commitment to excellence ensures that every project we undertake meets the highest standards.</p>
                        </div>
                        <div className="value-cards cards-three">
                            <p className="value-head">Collaboration</p>
                            <p className="value-details">We believe in working closely with our clients to truly understand their needs and deliver customized solutions.</p>
                        </div>
                        <div className="value-cards cards-four">
                            <p className="value-head">Integrity</p>
                            <p className="value-details">Honesty and transparency are at the core of our business. We build trust through open communication and ethical practices.</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="why-choose" id="about">
                <div className="container">
                    <h2 className="out-line-heading">Why Choose Us?</h2>
                    <div className="choose-card">
                        <div className="choose-cards choose-one">
                            <p className="choose-head">Expertise and Experience</p>
                            <p className="choose-details">With years of experience in the industry, we bring a wealth of knowledge and expertise to every project.</p>
                        </div>
                        <div className="choose-cards choose-two">
                            <p className="choose-head">Customized Solutions</p>
                            <p className="choose-details">We understand that every business is unique. Our solutions are tailored to fit your specific needs and goals, ensuring maximum effectiveness.</p>
                        </div>
                        <div className="choose-cards choose-three">
                            <p className="choose-head">Client-Centric Approach</p>
                            <p className="choose-details">Your satisfaction is our priority. We collaborate closely with you to understand your vision and deliver solutions that exceed expectations.</p>
                        </div>
                        <div className="choose-cards choose-four">
                            <p className="choose-head">Innovation and Quality</p>
                            <p className="choose-details">We stay ahead of industry trends and use cutting-edge technologies to deliver high-quality, innovative solutions that give you a competitive edge.</p>
                        </div>
                    </div>
                    <div className="counter-section">
                        <Counter img={ProjectsCompleted} number="20" label="Projects Completed" />
                        <Counter img={HappyClients} number="12" label="Happy Clients" />
                        <Counter img={ServeServices} number="50" label="Serve Services" />
                        <Counter img={Available} number="24" label="Support Available" />
                    </div>
                </div>
            </section>
            <Discuss />
        </>
    )
}

export default About;