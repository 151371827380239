import React, { useEffect, useState } from "react";
import upArrow from '../../assets/image/arrow-up.svg';
import { ButtonNew } from "../Button/Button";
import './ScrollTop.css';

const ScrollTop = () => {
    const [isVisible, setIsVisible] = useState(false);

    
    useEffect(() => {
        const handleScroll = () => {
          if (window.scrollY > 500) {
            setIsVisible(true);
          } else {
            setIsVisible(false);
          }
        };
    
        window.addEventListener('scroll', handleScroll);
    
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    return (
        <>
            <div className="container">
                <div className="scroll-top" onClick={scrollToTop} style={{ opacity: isVisible ? '1' : '0' }}>
                    <img src={upArrow} />
                </div>
            </div>
        </>
    )
}

export default ScrollTop;