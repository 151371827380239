import React, { useEffect } from "react";
import Logo from "../assets/image/sattva.svg";
import ProjectsCompleted from "../assets/image/projects-completed.svg";
import HappyClients from "../assets/image/happy-clients.svg";
import ServeServices from "../assets/image/serve-services.svg";
import Available from "../assets/image/available.svg";

import Discuss from "../components copy/Discuss";
import Counter from "../components copy/Counter";

import '../assets/css/InnerPage.css';
import { useLocation } from "react-router-dom";
import Loader from "../components copy/Loader/Loader";
import Waves from "../components copy/Waves";
import { Helmet } from "react-helmet-async";

const Services = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
    return (
        <>
            <Helmet>
                <title>Sattva Services - Web Design, Digital Marketing & Branding by Pravin Lagariya</title>
                <meta name="description" content="Explore Sattva's wide range of services offered by Pravin Lagariya, including web design, digital marketing, branding, and more." />
                <meta name="keywords" content="Sattva services, Pravin Lagariya, web design, digital marketing, branding, SEO, creative solutions" />
                <link rel="canonical" href="https://pravinlagariya.in/services" />
            </Helmet>
            <Loader />
            <Waves />
            <section className="inner-banner">
                <div className="container">
                    <div className="banner-section">
                        <div className="inner-banner-text">
                            <h2 className="inner-heding">Our Services</h2>
                            <p className="inner-details">At <span>SATTVA</span>, we offer a comprehensive suite of digital services designed to enhance your online presence and drive your business growth. Each service is tailored to meet your unique needs, ensuring optimal results and client satisfaction.</p>
                        </div>
                        <div className="inner-banner-img">
                            <figure>
                                <img src={Logo} alt="Logo" />
                            </figure>
                        </div>
                    </div>
                </div>
            </section>
            <section id="graphic">
                <div className="container">
                    <h2 className="out-line-heading">Graphic & UI/UX Design</h2>
                    <p className="inner-details">Our creative team excels in delivering visually appealing and user-centric designs. From logos and branding materials to user interfaces, we focus on creating designs that not only look great but also provide an exceptional user experience.</p>
                    <div className="graphic two-card-inners">
                        <div className="we-offer">
                            <p>What We Offer:</p>
                            <ul>
                                <li>Logo and Brand Identity Design</li>
                                <li>Website and Mobile App Design</li>
                                <li>User Experience (UX) Research and Design</li>
                                <li>User Interface (UI) Design</li>
                                <li>Marketing Materials and Infographics</li>
                            </ul>
                        </div>
                        <div className="technologies">
                            <p>Technologies and Software:</p>
                            <ul>
                                <li>Adobe Photoshop</li>
                                <li>Adobe Illustrator</li>
                                <li>Adobe XD</li>
                                <li>Figma</li>
                                <li>Sketch</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            <section id="design">
                <div className="container">
                    <h2 className="out-line-heading">Web Design and Development</h2>
                    <p className="inner-details">We create responsive, user-friendly websites that reflect your brand's identity and values. Our team ensures that your website not only looks stunning but also functions seamlessly across all devices, offering a great user experience.</p>
                    <div className="design two-card-inners">
                        <div className="we-offer">
                            <p>What We Offer:</p>
                            <ul>
                                <li>Custom Website Design</li>
                                <li>Responsive Web Design</li>
                                <li>E-commerce Website Development</li>
                                <li>Content Management Systems (CMS)</li>
                                <li>Website Maintenance and Support</li>
                            </ul>
                        </div>
                        <div className="technologies">
                            <p>Technologies and Software:</p>
                            <ul>
                                <li>HTML5, CSS3, JavaScript</li>
                                <li>Bootstrap, Foundation</li>
                                <li>WordPress, Joomla, Drupal</li>
                                <li>WooCommerce, Shopify, Magento</li>
                                <li>Webflow</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            <section id="development">
                <div className="container">
                    <h2 className="out-line-heading">Web Development</h2>
                    <p className="inner-details">Our web development services cover everything from simple websites to complex web applications. We use the latest technologies and best practices to build secure, scalable, and high-performing web solutions.</p>
                    <div className="development two-card-inners">
                        <div className="we-offer">
                            <p>What We Offer:</p>
                            <ul>
                                <li>Front-End and Back-End Development</li>
                                <li>Custom Web Applications</li>
                                <li>API Integration and Development</li>
                                <li>Database Design and Management</li>
                                <li>Web Security Solutions</li>
                            </ul>
                        </div>
                        <div className="technologies">
                            <p>Technologies and Software:</p>
                            <ul>
                                <li>PHP, Python, Ruby, Java</li>
                                <li>React, Angular, Vue.js</li>
                                <li>Node.js, Express.js</li>
                                <li>MySQL, PostgreSQL, MongoDB</li>
                                <li>AWS, Azure, Google Cloud</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            <section id="marketing">
                <div className="container">
                    <h2 className="out-line-heading">Digital Marketing</h2>
                    <p className="inner-details">Boost your online presence with our strategic digital marketing services. We create customized marketing strategies to help you reach your target audience, increase engagement, and drive conversions.</p>
                    <div className="marketing two-card-inners">
                        <div className="we-offer">
                            <p>What We Offer:</p>
                            <ul>
                                <li>Search Engine Optimization (SEO)</li>
                                <li>Pay-Per-Click (PPC) Advertising</li>
                                <li>Social Media Marketing</li>
                                <li>Content Marketing</li>
                                <li>Email Marketing Campaigns</li>
                            </ul>
                        </div>
                        <div className="technologies">
                            <p>Technologies and Software:</p>
                            <ul>
                                <li>Google Analytics, Google Ads</li>
                                <li>Facebook Ads Manager, LinkedIn Ads</li>
                                <li>Hootsuite, Buffer</li>
                                <li>MailChimp, Constant Contact</li>
                                <li>SEMrush, Ahrefs</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            <section className="our-values" id="services">
                <div className="container">
                    <h2 className="out-line-heading">Our Process</h2>
                    <div className="value-card">
                        <div className="value-cards cards-one">
                            <p className="value-head">Consultation</p>
                            <p className="value-details">We start with an in-depth consultation to understand your business goals, target audience, and specific needs.</p>
                        </div>
                        <div className="value-cards cards-two">
                            <p className="value-head">Planning</p>
                            <p className="value-details">Based on our consultation, we develop a strategic plan tailored to your objectives.</p>
                        </div>
                        <div className="value-cards cards-three">
                            <p className="value-head">Design & Development</p>
                            <p className="value-details">Our creative and technical teams bring your vision to life with innovative designs and robust development.</p>
                        </div>
                        <div className="value-cards cards-four">
                            <p className="value-head">Testing & Launch</p>
                            <p className="value-details">We thoroughly test all solutions to ensure they meet our high standards before launching.</p>
                        </div>
                        <div className="value-cards cards-five">
                            <p className="value-head">Support & Optimization</p>
                            <p className="value-details">Post-launch, we provide ongoing support and optimization to ensure continued success.</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="why-choose" id="services">
                <div className="container">
                    <h2 className="out-line-heading">Why Choose Us?</h2>
                    <div className="choose-card">
                        <div className="choose-cards choose-one">
                            <p className="choose-head">Expertise and Experience</p>
                            <p className="choose-details">With years of experience in the industry, we bring a wealth of knowledge and expertise to every project.</p>
                        </div>
                        <div className="choose-cards choose-two">
                            <p className="choose-head">Customized Solutions</p>
                            <p className="choose-details">We understand that every business is unique. Our solutions are tailored to fit your specific needs and goals, ensuring maximum effectiveness.</p>
                        </div>
                        <div className="choose-cards choose-three">
                            <p className="choose-head">Client-Centric Approach</p>
                            <p className="choose-details">Your satisfaction is our priority. We collaborate closely with you to understand your vision and deliver solutions that exceed expectations.</p>
                        </div>
                        <div className="choose-cards choose-four">
                            <p className="choose-head">Innovation and Quality</p>
                            <p className="choose-details">We stay ahead of industry trends and use cutting-edge technologies to deliver high-quality, innovative solutions that give you a competitive edge.</p>
                        </div>
                    </div>
                    <div className="counter-section">
                        <Counter img={ProjectsCompleted} number="20" label="Projects Completed" />
                        <Counter img={HappyClients} number="12" label="Happy Clients" />
                        <Counter img={ServeServices} number="50" label="Serve Services" />
                        <Counter img={Available} number="24" label="Support Available" />
                    </div>
                </div>
            </section>
            <Discuss />
        </>
    )
}

export default Services;