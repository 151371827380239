import React from 'react';
import Logo from '../../assets/image/logo.png';
import Call from '../../assets/image/phone.svg';
import Mail from '../../assets/image/mail.svg';
import Location from '../../assets/image/location.svg';
import './Footer.css';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <footer className='footer'>
            <div className='container'>
                <div className='footer-inner'>
                    <div className='footer-logo'>
                        <div>
                            <img src={Logo} alt='logo' />
                        </div>
                    </div>
                    <div className='footer-links'>
                        <h6>Quick Links</h6>
                        <ul>
                            <li><Link to='/'>Home</Link></li>
                            <li><Link to='/about'>About us</Link></li>
                            <li><Link to='/services'>Services</Link></li>
                            <li><Link to='/contact'>Contact</Link></li>
                        </ul>
                    </div>
                    <div className='footer-links'>
                        <h6>Services</h6>
                        <ul>
                            <li><Link to='/graphic&ui-ux-design'>Graphic & UI/UX</Link></li>
                            <li><Link to='/web-design'>Web Design</Link></li>
                            <li><Link to='/web-development'>Web Development</Link></li>
                            <li><Link to='/digital-marketing'>Digital Marketing</Link></li>
                        </ul>
                    </div>
                    <div className='footer-logo footer-links'>
                        <h6>Contact Now</h6>
                        <ul>
                            <li>
                                <Link to='tel:+919173460204'>
                                    <img src={Call} alt='Call' />
                                    +91 9173460204
                                </Link>
                            </li>
                            <li>
                                <Link to='mailto:athesattvastudio@gmail.com'>
                                    <img src={Mail} alt='Mail' />
                                    athesattvastudio@gmail.com
                                </Link>
                            </li>
                            <li>
                                <Link to='/'>
                                    <img src={Location} alt='location' />
                                    Jamnagar, Gujrat, India
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className='footer-copy-right'>
                    <p>Copyright 2023. All Rights Reserved.</p>
                </div>
            </div>
        </footer>
    )
}

export default Footer;