import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Logo from '../../assets/image/logo.png';
import Dropdown from '../../assets/image/chevron-down.svg';
import './Header.css'

const Header = () => {
    const [isNavOpen, setIsNavOpen] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const location = useLocation();
    const { pathname } = location;

    const closeDropdown = (event) => {
        if (!event.target.closest('.dropdown')) {
            setIsDropdownOpen(false);
        }
    };

    React.useEffect(() => {
        if (isDropdownOpen) {
            document.addEventListener('click', closeDropdown);
        } else {
            document.removeEventListener('click', closeDropdown);
        }

        return () => {
            document.removeEventListener('click', closeDropdown);
        };
    }, [isDropdownOpen]);

    return (
        <header>
            <nav className='container'>
                <div className="button-logo">
                    <Link to='/' className="logo">
                        <img src={Logo} alt='logo' />
                    </Link>
                    <button
                        id='nav-icon'
                        className={`md:hidden ml-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ${isNavOpen ? 'toggle-open' : 'toggle-hidden'}`}
                        onClick={() => setIsNavOpen(!isNavOpen)}
                    >
                        <span></span>
                        <span></span>
                        <span></span>
                    </button>
                </div>
                <div className={`menu-links ${isNavOpen ? 'menu-open' : 'menu-hidden'}`}>
                    <ul className="header-menu">
                        <li>
                            <Link
                                to="/"
                                className={`${pathname === '/' ? 'active-menu' : ''}`}
                                onClick={() => setIsNavOpen(!isNavOpen)}
                            >
                                Home
                            </Link>
                        </li>
                        <li>
                            <Link
                                to="/about"
                                className={`${pathname === '/about' ? 'active-menu' : ''}`}
                                onClick={() => setIsNavOpen(!isNavOpen)}
                            >
                                About us
                            </Link>
                        </li>
                        <li className="dropdown">
                            <Link
                                to="/services"
                                className={`${pathname.startsWith('/services') ? 'active-menu' : ''}`}
                                onClick={() => setIsNavOpen(!isNavOpen)}
                            >
                                Services
                            </Link>
                            <span
                                className="drop-arrow"
                                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                            >
                                <img className={`dropdown-arrow ${isDropdownOpen ? 'add' : 'remove'}`} src={Dropdown} alt="Dropdown" />
                            </span>
                            <div className={`dropdown-menus ${isDropdownOpen ? 'block' : 'hidden'}`}>
                                <ul onClick={() => setIsNavOpen(!isNavOpen)}>
                                    <li className="nav-link">
                                        <Link
                                            to="/graphic&ui-ux-design"
                                            className={pathname === '/graphic&ui-ux-design' ? 'active-menu' : ''}
                                            onClick={() => setIsDropdownOpen(false)}
                                        >
                                            Graphic & UI/UX Design
                                        </Link>
                                    </li>
                                    <li className="nav-link">
                                        <Link
                                            to="/web-design"
                                            className={pathname === '/web-design' ? 'active-menu' : ''}
                                            onClick={() => setIsDropdownOpen(false)}
                                        >
                                            Web Design
                                        </Link>
                                    </li>
                                    <li className="nav-link">
                                        <Link
                                            to="/web-development"
                                            className={pathname === '/web-development' ? 'active-menu' : ''}
                                            onClick={() => setIsDropdownOpen(false)}
                                        >
                                            Web Development
                                        </Link>
                                    </li>
                                    <li className="nav-link">
                                        <Link
                                            to="/digital-marketing"
                                            className={pathname === '/digital-marketing' ? 'active-menu' : ''}
                                            onClick={() => setIsDropdownOpen(false)}
                                        >
                                            Digital Marketing
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li>
                            <Link
                                to="/contact"
                                className={`${pathname === '/contact' ? 'active-menu' : ''}`}
                                onClick={() => setIsNavOpen(!isNavOpen)}
                            >
                                Contact
                            </Link>
                        </li>
                    </ul>
                </div>
            </nav>
        </header>
    );
}

export default Header;
