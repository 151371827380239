import React, { useEffect } from "react";
import DesignGU from "../assets/image/designGU.png";
import ProjectsCompleted from "../assets/image/projects-completed.svg";
import HappyClients from "../assets/image/happy-clients.svg";
import ServeServices from "../assets/image/serve-services.svg";
import Available from "../assets/image/available.svg";

import Discuss from "../components copy/Discuss";
import Counter from "../components copy/Counter";

import '../assets/css/InnerPage.css';
import { useLocation } from "react-router-dom";
import Loader from "../components copy/Loader/Loader";
import Waves from "../components copy/Waves";

function Design() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <>
      <Loader />
      <Waves />
      <section className="inner-banner">
        <div className="container">
          <div className="banner-section">
            <div className="inner-banner-text">
              <h2 className="inner-heding">Transform Your Brand with Creative Excellence</h2>
              <p className="inner-details">At <span>SATTVA</span>, we specialize in Graphic Design and UI/UX Design services that elevate brands and create memorable user experiences. Our team of creative professionals combines artistic flair with user-centric design principles to deliver compelling visual identities and intuitive interfaces.</p>
            </div>
            <div className="inner-banner-img">
              <figure>
                <img src={DesignGU} alt="Graphic & UI/UX Design section image" />
              </figure>
            </div>
          </div>
        </div>
      </section>
      <section className="our-values" id="design">
        <div className="container">
          <h2 className="out-line-heading">Graphic Design Services</h2>
          <div className="value-card">
            <div className="value-cards cards-one">
              <p className="value-head">Logo Design</p>
              <p className="value-details">Craft unique logos that embody your brand’s essence and leave a lasting impression.</p>
            </div>
            <div className="value-cards cards-two">
              <p className="value-head">Brand Identity</p>
              <p className="value-details">Develop cohesive visual identities, including color palettes, typography, and brand guidelines.</p>
            </div>
            <div className="value-cards cards-three">
              <p className="value-head">Marketing Collaterals</p>
              <p className="value-details">Design impactful materials such as brochures, flyers, and posters that communicate your brand message effectively.</p>
            </div>
          </div>
        </div>
      </section>
      <section className="our-values" id="design">
        <div className="container">
          <h2 className="out-line-heading">UI/UX Design Services</h2>
          <div className="value-card">
            <div className="value-cards cards-one">
              <p className="value-head">User Research</p>
              <p className="value-details">Conduct in-depth research to understand user needs, behaviors, and pain points.</p>
            </div>
            <div className="value-cards cards-two">
              <p className="value-head">Wireframing and Prototyping</p>
              <p className="value-details">Create interactive wireframes and prototypes to visualize user journeys and interactions.</p>
            </div>
            <div className="value-cards cards-three">
              <p className="value-head">User Interface Design</p>
              <p className="value-details">Design intuitive interfaces that prioritize usability, accessibility, and user engagement.</p>
            </div>
          </div>
        </div>
      </section>
      <section className="why-choose" id="design">
        <div className="container">
          <h2 className="out-line-heading">Our Approach</h2>
          <div className="choose-card">
            <div className="choose-cards choose-one">
              <p className="choose-head">Discovery and Strategy</p>
              <p className="choose-details">Dive deep into your brand’s values, target audience, and business goals to tailor our design approach.</p>
            </div>
            <div className="choose-cards choose-two">
              <p className="choose-head">Design Iteration</p>
              <p className="choose-details">Collaborate closely with you to refine concepts, ensuring they align with your brand identity and user expectations.</p>
            </div>
            <div className="choose-cards choose-three">
              <p className="choose-head">User-Centered Design</p>
              <p className="choose-details">Place users at the forefront of our design process, aiming to create seamless and enjoyable experiences.</p>
            </div>
            <div className="choose-cards choose-four">
              <p className="choose-head">Continuous Improvement</p>
              <p className="choose-details">Embrace feedback and analytics to continuously optimize designs for better performance and user satisfaction.</p>
            </div>
          </div>
          <div className="counter-section">
            <Counter img={ProjectsCompleted} number="20" label="Projects Completed" />
            <Counter img={HappyClients} number="12" label="Happy Clients" />
            <Counter img={ServeServices} number="50" label="Serve Services" />
            <Counter img={Available} number="24" label="Support Available" />
          </div>
        </div>
      </section>
      <Discuss />
    </>
  )
}

export default Design;
