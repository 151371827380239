import React, { useEffect, useState } from 'react';
import Logo from '../../assets/image/logo.png';
import './Loader.css';
import Waves from '../Waves';


const Loader = () => {
    const [isLoader, setIsLoader] = useState(true);
    useEffect(() => {
        const timer = setTimeout(() => {
            setIsLoader(false);
        }, 1000);
        return () => clearTimeout(timer);
    }, []);
    return (
        <>
            {isLoader && (
                <>
                    <div className='fixed top-0 right-0 w-screen h-screen bg-black z-50'>
                        <div className='flex justify-center items-center h-full'>
                            <img className='w-40' src={Logo} alt='Logo' />
                        </div>
                        <Waves />
                    </div>
                </>
            )}
        </>
    )
}

export default Loader;